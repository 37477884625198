<template>
  <div class="game-container">
    <SpeakingSentence
      :gameList="gameList"
      :isClick="false"
      :titleInfo="titleInfo"
      :lesson="371"
      :hasMenu="false"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "kàn yī kàn,  shuō yī shuō",
        hanzi: "看一看,说一说",
      },
      gameList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-37/image.svg"),
        },
      ],
    };
  },
  components: {
    SpeakingSentence,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>