<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :lessonNO="372"
      :noFirstImg="true"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G37-click-n-open/lesson-37/dog-background.svg"),
      optionList: [
        {
          clickImg: require("@/assets/img/16-GAMES/G37-click-n-open/lesson-37/dog-closet-1.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          clickImg: require("@/assets/img/16-GAMES/G37-click-n-open/lesson-37/dog-closet-2.svg"),
          id: 2,
        },
        {
          clickImg: require("@/assets/img/16-GAMES/G37-click-n-open/lesson-37/dog-closet-3.svg"),
          id: 3,
        },
        {
          clickImg: require("@/assets/img/16-GAMES/G37-click-n-open/lesson-37/dog-closet-4.svg"),
          id: 4,
        },
      ],
      maskImgList: [
        {
          id: 1,
          centencePinyin: "Zhèr méiyǒu gǒu",
          sentenceHanzi: "这儿没有狗。",
        },

        {
          id: 2,
          centencePinyin: "Zhèr méiyǒu gǒu",
          sentenceHanzi: "这儿没有狗。",
        },
        {
          id: 3,
          centencePinyin: "Zhèr méiyǒu gǒu",
          sentenceHanzi: "这儿没有狗。",
        },
        {
          id: 4,
          centencePinyin: "Zhèr yǒu gǒu",
          sentenceHanzi: "这儿有狗。",
        },
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











