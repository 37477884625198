<template>
  <div class="game-container">
    <ClickImgToShowSentenceGame
      :bgImg="bgImg"
      :optionList="optionList"
      :maskImgList="maskImgList"
      @showCoeerctImg="showCoeerctImg"
      :lessonNO="371"
      :noFirstImg="true"
    />
  </div>
</template>

<script>
import ClickImgToShowSentenceGame from "@/components/Course/GamePage/ClickImgToShowSentenceGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G37-click-n-open/lesson-37/cat-background.svg"),
      optionList: [
        {
          clickImg: require("@/assets/img/16-GAMES/G37-click-n-open/lesson-37/cat-closet-1.svg"),
          id: 1,
          isShowCorrect: false,
        },
        {
          clickImg: require("@/assets/img/16-GAMES/G37-click-n-open/lesson-37/cat-closet-2.svg"),
          id: 2,
        },
        {
          clickImg: require("@/assets/img/16-GAMES/G37-click-n-open/lesson-37/cat-closet-3.svg"),
          id: 3,
        },
        {
          clickImg: require("@/assets/img/16-GAMES/G37-click-n-open/lesson-37/cat-closet-4.svg"),
          id: 4,
        },
        {
          clickImg: require("@/assets/img/16-GAMES/G37-click-n-open/lesson-37/cat-closet-5.svg"),
          id: 5,
        },
      ],
      maskImgList: [
        {
          id: 1,
          centencePinyin: "Zhèr méiyǒu māo ",
          sentenceHanzi: "这儿没有猫。",
        },
        {
          id: 2,
          centencePinyin: "Zhèr yǒu māo ",
          sentenceHanzi: "这儿有猫。",
        },
        {
          id: 3,
          centencePinyin: "Zhèr méiyǒu māo ",
          sentenceHanzi: "这儿没有猫。",
        },
        {
          id: 4,
          centencePinyin: "Zhèr méiyǒu māo ",
          sentenceHanzi: "这儿没有猫。",
        },
        {
          id: 5,
          centencePinyin: "Zhèr méiyǒu māo ",
          sentenceHanzi: "这儿没有猫。",
        },
      ],
    };
  },
  components: {
    ClickImgToShowSentenceGame,
  },
  methods: {
    showCoeerctImg(index) {
      this.optionList[index - 1].isShowCorrect = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











