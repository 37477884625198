<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "我会认",
          isUse: false,
          content: [
            {
              pinyin: "gèzi",
              hanzi: "个子",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "gāo",
              hanzi: "高",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "shǒu",
              hanzi: "手",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "méiyǒu",
              hanzi: "没有",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },
          
            //、、和、姐姐、弟弟

            // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
          ],
        },
        {
          title: "我会用",
          isUse: true,
          contentList: [
            [
              {
                pinyin: "zhīshídiǎn",
                hanzi: "知识点",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "“",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 3,
              },
              {
                pinyin: "yǒu",
                hanzi: "“有”",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "de",
                hanzi: "的",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "fǎn yì cí",
                hanzi: "反义词",
                symbol: "",
                group: 1,
                line: 1,
                index: 5,
                symbolType: 0,
              },
              
              {
                pinyin: "méiyǒu",
                hanzi: "“没有”",
                symbol: "",
                group: 1,
                line: 1,
                index: 7,
                symbolType: 0,
              },
             
            ],
            [
              {
                pinyin: "rú",
                hanzi: "如",
                symbol: "",
                group: 1,
                line: 2,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 2,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "tú",
                hanzi: "图",
                symbol: "",
                group: 1,
                line: 2,
                index: 2,
                symbolType: 0,
              },
              {
                pinyin: "tú",
                hanzi: "图",
                symbol: "",
                group: 1,
                line: 2,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "méi",
                hanzi: "没",
                symbol: "",
                group: 1,
                line: 2,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "yǒu",
                hanzi: "有",
                symbol: "",
                group: 1,
                line: 2,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "gǒu",
                hanzi: "狗",
                symbol: "",
                group: 1,
                line: 1,
                index: 6,
                symbolType: 0,
              },
             
              {
                pinyin: "",
                name: "",
                symbol: "。",
                group: 1,
                line: 2,
                index: 8,
                symbolType: 6,
              },
            ],
          ],
        },
        {
          title: "我会说",
          isUse: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "yòng",
              hanzi: "用",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "Zhōngwén",
              hanzi: "中文",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "jiǎn dān dē ",
              hanzi: "简单地",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "miáo shù",
              hanzi: "描述",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "shēn gāo ",
              hanzi: "身高",
              symbol: "",
              group: 2,
              index: 4,
              symbolType: 0,
            },
            
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>